// import React, { useState } from 'react';
// import PopUpBox from '../PopUpBox/PopUpBox';
// import { Paper } from '@mui/material';
// import { CustomTextField } from '../CustomTextField/CustomTextField';
// import SolidButton from '../SolidButton/SolidButton';
// import { useOtpContext } from '../../Providers/OtpProvider';
// import AlertBox from '../AlertBox/AlertBox';
// import {

//   TextField,
// } from '@mui/material';

// const SignInPopUp = () => {
//   const { phoneNo, id, setPhoneNo, errorMsg, loading, setId, getOtp } = useOtpContext();
//   const [helperPhone, setHelperPhone] = useState("");
//   const isValidPhoneNumber = (number) => {
//     var str = "Enter a valid phone number";
//     var nullStr = "";
//     if ((number.length !== 10) || isNaN(number)) {
//       setHelperPhone(str);
//     } else {
//       setHelperPhone(nullStr);
//     }
//   }
//   const handlePhone = (value) => {
//     isValidPhoneNumber(value.target.value);
//     setPhoneNo(value.target.value);
//   }
//   return (
//     <PopUpBox >
//       <Paper variant="outlined"
//         sx={{ width: "98%", height: "480px" }}>
//         <div style={{
//           width: '100%',
//           textAlign: 'center',
//           marginTop: "30px"
//         }
//         }>
//           {errorMsg.length === 0 ? null : <AlertBox severity="error" text={errorMsg} />}

//           <div
//             style={{
//               textAlign: 'center',
//               margin: "10px",
//               fontFamily: "Roboto,Helvetica,Arial,sans-serif",
//             }}
//           >
//             <Paper elevation={0} />
//           Admin Login
//             <Paper />
//           </div>
//           {/* <TextField

// value={phoneNo}
//             onChange={handlePhone}
//             helperText={helperPhone}
//             variant="outlined"
//                           id='phoneNo'
//                           name='phoneNo'
//                           label='Enter Mobile Number'

//                           // InputLabelProps={{
//                           //   shrink: true,
//                           // }}
//                         /> */}
//          <CustomTextField value={phoneNo}
//             onChange={handlePhone}
//             helperText={helperPhone}
//             label="Phone No"
//             variant="outlined" />

//           {/* <CustomTextField value = { id }
//           onChange = { (e) => setId(e.target.value) }
//           key = "agency-id"
//           label = "Agency ID"
//           type = "password"
//           variant = "outlined" /> */}

//           <div style={{ alignItems: "center", margin: '30px', display: 'inline-flex' }}>
//             <div id="recaptcha-container" ></div>
//           </div>

//           <SolidButton
//             disabled={loading}
//             display="block"
//             label="Get Otp"
//             size="large"
//             onHover="#398139"
//             onPress={getOtp} />
//         </div>
//       </Paper>
//     </PopUpBox>);
// }

// export default SignInPopUp;

import React, { useState } from "react";
import PopUpBox from "../PopUpBox/PopUpBox";
import { Box, Paper, Typography, Grid, Container } from "@mui/material";
import { CustomTextField } from "../CustomTextField/CustomTextField";
import SolidButton from "../SolidButton/SolidButton";
import { useOtpContext } from "../../Providers/OtpProvider";
import AlertBox from "../AlertBox/AlertBox";
import { useNavigate } from "react-router-dom";

import { TextField } from "@mui/material";

const SignInPopUp = () => {
  const { phoneNo, setPhoneNo, errorMsg, loading, setId, getOtp } =
    useOtpContext();
  const [helperPhone, setHelperPhone] = useState("");
  const navigate = useNavigate();

  const redirectToPrivacyPolicy = () => {
    navigate("/privacy");
  };

  const isValidPhoneNumber = (number) => {
    var str = "Enter a valid phone number";
    var nullStr = "";
    if (number.length !== 10 || isNaN(number)) {
      setHelperPhone(str);
    } else {
      setHelperPhone(nullStr);
    }
  };
  const handlePhone = (value) => {
    isValidPhoneNumber(value.target.value);
    setPhoneNo(value.target.value);
  };
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {errorMsg.length === 0 ? null : (
        <AlertBox severity="error" text={errorMsg} />
      )}

      <Container
        sx={{
          display: "grid",
          justifyContent: "center",
          width: "350px",
          background: "white",
          textAlign: "center",
          padding: "5px",
          borderRadius: "10px",
          boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        }}
      >
        <Typography
          my={4}
          sx={{
            fontWeight: 900,
            fontSize: "30px",
            fontStyle: "normal",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            fontFamily: "serif",
          }}
        >
          Admin Login
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            sx={{
              width: "300px",
            }}
            value={phoneNo}
            onChange={handlePhone}
            helperText={helperPhone}
            variant="outlined"
            id="phoneNo"
            name="phoneNo"
            label="Enter Mobile Number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        {/* <CustomTextField value={phoneNo}
            onChange={handlePhone}
            helperText={helperPhone}
            label="Phone No"
            variant="outlined" /> */}
        {/* <CustomTextField value = { id }
          onChange = { (e) => setId(e.target.value) }
          key = "agency-id"
          label = "Agency ID"
          type = "password"
          variant = "outlined" /> */}
        <div
          style={{
            alignItems: "center",
            margin: "30px",
            display: "inline-flex",
          }}
        >
          <div id="recaptcha-container"></div>
        </div>
        <SolidButton
          sx={{ width: "240px" }}
          disabled={loading}
          display="block"
          label="Get Otp"
          size="large"
          onHover="#398139"
          onPress={getOtp}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <span
            style={{
              fontSize: "13px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            Copyright,VeriDocs.
          </span>
          <span
            style={{
              fontSize: "13px",
              marginTop: "10px",
              color: "#66aeed",
              cursor: "pointer",
              marginLeft: "5px",
            }}
            onClick={redirectToPrivacyPolicy}
          >
            Privacy Policy
          </span>{" "}
        </div>{" "}
      </Container>
    </Box>
  );
};

export default SignInPopUp;
