// import React from 'react';
// import SolidButton from '../SolidButton/SolidButton';
// import { Paper, Typography } from '@mui/material';
// import PopUpBox from '../PopUpBox/PopUpBox';
// import OtpInput from 'react-otp-input-rc-17';
// import { useOtpContext } from '../../Providers/OtpProvider';
// import { useNavigate } from 'react-router-dom';
// import AlertBox from '../AlertBox/AlertBox';

// const OtpPopUp = () => {

//     const navigate = useNavigate();
//     const {otp, phoneNo, setPopUp, errorMsg, setOtp, verifyOtp } = useOtpContext();

//     return (<PopUpBox >
//         <Paper variant = "outlined"
//         sx = {{ width: "98%", height: "480px" }}>
//         <div style = {
//             {
//                 width: '100%',
//                 textAlign: 'center',
//             }
//         }>
//             <div style = {
//                 { margin: '60px'}
//             } >
//               <Typography variant='h5'> Enter the OTP sent to</Typography>
//               <Typography variant='h5'>{'+91 ' + phoneNo} </Typography>
//             </div>

//             {errorMsg.length === 0 ? null : <AlertBox severity="error" text={errorMsg}/>}

//             <div style={{textAlign: 'center', paddingLeft: '8.3em', paddingTop: '2em', paddingBottom: '4em'}}>
//               <OtpInput className="OtpInput"
//                 key="otp-input"
//                 value={otp}
//                 onChange={ value => setOtp(value)}
//                 numInputs={6}/>
//             </div>
//           <SolidButton label = "Go Back"
//               size = "large"
//               display = "inline"
//               variant = "outlined"
//               background = "white"
//               color = "green"
//               onPress = { () => {
//                 setPopUp("1");
//               }} />

//             <SolidButton
//               display = "inline"
//               label = "Verify OTP"
//               size = "large"
//               onHover = "#398139"
//               onPress = { () => {
//                   verifyOtp(() => {
//                       navigate('/dashboard');
//                   });
//               } }
//               />
//         </div>
//         </Paper>
//       </PopUpBox>);
// }

// export default OtpPopUp;

import React from "react";
import SolidButton from "../SolidButton/SolidButton";
import { Paper, Typography } from "@mui/material";
import PopUpBox from "../PopUpBox/PopUpBox";
import OtpInput from "react-otp-input-rc-17";
import { useOtpContext } from "../../Providers/OtpProvider";
import { useNavigate } from "react-router-dom";
import AlertBox from "../AlertBox/AlertBox";

import { Box, Grid, Container } from "@mui/material";

const OtpPopUp = () => {
  const navigate = useNavigate();
  const { otp, phoneNo, setPopUp, errorMsg, setOtp, verifyOtp } =
    useOtpContext();

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {errorMsg.length === 0 ? null : (
        <AlertBox severity="error" text={errorMsg} />
      )}

      <Container
        sx={{
          display: "grid",
          justifyContent: "center",
          width: "350px",
          background: "white",
          textAlign: "center",
          padding: "5px",
          borderRadius: "10px",
          boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        }}
      >
        <div style={{ margin: "40px" }}>
          <Typography sx={{ display: "flex" }}>
            {" "}
            Kindly enter the OTP sent to:
          </Typography>
          <Typography
            sx={{ display: "flex", fontWeight: "900", marginTop: "3px" }}
          >
            {"+91 " + phoneNo}{" "}
          </Typography>
        </div>

        <div
          style={{
            textAlign: "center",
            paddingLeft: "3.3em",
            paddingTop: "2em",
            paddingBottom: "3em",
          }}
        >
          <OtpInput
            className="OtpInput"
            key="otp-input"
            value={otp}
            onChange={(value) => setOtp(value)}
            numInputs={6}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <SolidButton
            label="Go Back"
            size="large"
            display="inline"
            variant="outlined"
            background="white"
            color="green"
            onPress={() => {
              setPopUp("1");
            }}
          />

          <SolidButton
            display="inline"
            label="Verify OTP"
            size="large"
            onHover="#398139"
            onPress={() => {
              verifyOtp(() => {
                navigate("/dashboard");
              });
            }}
          />
        </div>
      </Container>
    </Box>
  );
};

export default OtpPopUp;
