// import React from 'react';
// import { useFieldVerifiersContext } from '../../Providers/FieldVerifiersProvider';
// import FieldVerifierCard from '../../Elements/FieldVerifierCard/FieldVerifierCard';
// import { useLocation } from 'react-router-dom';
// import { Grid } from '@mui/material';
// import './FieldVerifierPage.css';

// const FieldVerifierPage = () => {
//   const { fvs } = useFieldVerifiersContext();
//   const { state } = useLocation();
//   const mode = state?.mode || null; // Use optional chaining and a fallback

//   // Filter out empty fields and fields containing "8768715527"
//   const existingFVs = fvs.filter(element => element && element !== "8768715527");

//   return (
//     <div style={{ width: "100%" }}>
//       <Grid container style={{
//         margin: '0.4em 0em',
//         fontSize: '14px',
//         color: '#404040',
//         fontWeight: 'bold'
//       }}>
//         <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
//         <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>ID</Grid>
//         <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Name</Grid>
//         <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Phone</Grid>
//         <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Actions</Grid>
//       </Grid>
//       <hr style={{ width: '93%', margin: '0 auto 0.15em auto', border: '0.6px solid #dedede' }} />

//       {/* <div style={{ textAlign: 'center', margin: '0.5em 0', color: 'gray', fontSize: '14px' }}>
//         Existing Field Verifiers: {existingFVs.length}
//       </div> */}

//       {existingFVs.map((element, index) => (
//         <div key={index}>
//           <FieldVerifierCard
//             uid={element}
//             select={mode === "select" ? 1 : 0} />
//         </div>
//       ))}
//     </div>
//   );
// }

// export default FieldVerifierPage;

import React from "react";
import { useFieldVerifiersContext } from "../../Providers/FieldVerifiersProvider";
import FieldVerifierCard from "../../Elements/FieldVerifierCard/FieldVerifierCard";
import { useLocation } from "react-router-dom";
import { Divider, Grid } from "@mui/material";
import "./FieldVerifierPage.css";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
} from "@mui/material";

const FieldVerifierPage = () => {
  const { fvs } = useFieldVerifiersContext();
  const { state } = useLocation();
  const mode = state?.mode || null; // Use optional chaining and a fallback

  // Filter out empty fields and fields containing "8768715527"
  const existingFVs = fvs.filter(
    (element) => element && element !== "8768715527",
  );

  return (
    <>
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#404040" }}
              />
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#404040", width: "25%" }}
              >
                Email ID
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#404040", width: "25%" }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#404040", width: "25%" }}
              >
                Phone
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: "bold", color: "#404040", width: "25%" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Divider sx={{ color: "black", width: "100%" }} />
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {existingFVs.map((element, index) => (
              <div key={index}>
                <FieldVerifierCard
                  uid={element}
                  select={mode === "select" ? 1 : 0}
                />
              </div>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FieldVerifierPage;
