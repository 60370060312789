import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { database } from "../../Firebase/Firebase";
import ApprovedAssignmentDetails from "./ApprovedElement.tsx";
import { useToastProvider } from "../../Providers/ToastProvider.js";
import { Box, Divider } from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
} from "@mui/material";

const AssignmentManager = () => {
  const [assignments, setAssignments] = useState([]);
  const { showSuccess, showError } = useToastProvider();

  // Fetch all assignments
  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(database, "assignments"),
        );
        const allAssignments = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAssignments(allAssignments);
      } catch (error) {
        console.error("Error fetching assignments:", error);
      }
    };

    fetchAssignments();
  }, []);

  // Archive an assignment
  const archiveAssignment = async (id) => {
    try {
      await updateDoc(doc(database, "assignments", id), { status: "archived" });
      setAssignments((prev) =>
        prev.map((ass) =>
          ass.id === id ? { ...ass, status: "archived" } : ass,
        ),
      );
      window.alert("Assignment archived successfully!");
    } catch (error) {
      console.error("Error archiving assignment:", error);
      window.alert("Failed to archive the assignment.");
    }
  };

  // Unarchive an assignment
  const unarchiveAssignment = async (id) => {
    try {
      await updateDoc(doc(database, "assignments", id), { status: "approved" });
      setAssignments((prev) =>
        prev.map((ass) =>
          ass.id === id ? { ...ass, status: "approved" } : ass,
        ),
      );
      showSuccess("Unarchived Assingment Successfully!");
    } catch (error) {
      console.error("Error unarchiving assignment:", error);
      showError("Failed to unarchive the assignment.");
    }
  };

  return (
    <Box>
      {/* Render Archived Assignments */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ background: "#ffffff" }}>
              <TableCell style={{ fontWeight: 700, textAlign: "center" }}>
                ID
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Form Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  width: "25%",
                  textAlign: "center",
                }}
              >
                Verifier Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "25%",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Divider sx={{ color: "black", width: "100%" }} />

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {assignments.filter((ass) => ass.status === "archived").length >
            0 ? (
              assignments
                .filter((ass) => ass.status === "archived")
                .map((ass) => (
                  <ApprovedAssignmentDetails
                    key={ass.id}
                    {...ass}
                    assigned_to={ass.assigned_to}
                    assigned_name={ass.assigned_name}
                    form_name={ass.formName}
                    archiveAssignment={archiveAssignment}
                    unarchiveAssignment={unarchiveAssignment}
                  />
                ))
            ) : (
              <p>No archived assignments found.</p>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AssignmentManager;
