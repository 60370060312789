import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  policylist2,
  privacyText,
  privacyText2,
  information,
  retention,
  officer,
  thirdparty,
  security,
  details,
} from "./Text";
import { featuresText } from "./Text";
import { policylist } from "./Text";

export default function PrivacyPolicy() {
  return (
    <Box sx={{ width: "100%", padding: "50px" }}>
      <p
        style={{
          fontSize: "30px",
          marginTop: "20px",
          marginBottom: "30px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Privacy Policy{" "}
      </p>
      <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
        This Privacy Policy (“Policy”) was last updated on 15th March 2024. We
        may change or update this policy at any time, and the same will be
        updated here. If you are a VeriDocs user or customer, we shall notify
        the changes or updates either by sending an email or a notification on
        the VeriDocs App (as defined below).
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
        PLEASE READ THE POLICY CAREFULLY TO FULLY UNDERSTAND THE NATURE AND
        PURPOSE OF GATHERING INFORMATION, USAGE, DISCLOSURE, SECURITY PROCEDURES
        AND SHARING OF SUCH INFORMATION.{" "}
      </Typography>

      <p
        style={{
          fontSize: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "600",
        }}
      >
        1. POLICY STATEMENTS:
      </p>
      <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
        VeriDocs provides various background verification services for its
        clients through various means, which are mentioned below:
      </Typography>

      <ul style={{ paddingLeft: "40px" }}>
        {Object.values(policylist).map((policylist, index) => (
          <li>
            <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
              {" "}
              {policylist}
            </Typography>
          </li>
        ))}
      </ul>

      <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
        We sincerely believe that you should always know what data we collect
        from you, the purposes for which such data is used, and that you should
        have the ability to make informed decisions about what you want to share
        with us.
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
        Therefore, we want to be transparent about:{" "}
      </Typography>

      <ul style={{ paddingLeft: "40px" }}>
        {Object.values(policylist2).map((policylist2, index) => (
          <li>
            <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
              {" "}
              {policylist2}
            </Typography>
          </li>
        ))}
      </ul>

      <p
        style={{
          fontSize: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "600",
        }}
      >
        {" "}
        2. INFORMATION WE COLLECT AND HOW WE USE IT
      </p>
      <ul style={{ paddingLeft: "40px" }}>
        {Object.values(information).map((information, index) => (
          <li>
            <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
              {" "}
              {information}
            </Typography>
          </li>
        ))}
      </ul>
      <p
        style={{
          fontSize: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "600",
        }}
      >
        3. RETENTION OF PERSONAL INFORMATION
      </p>

      {Object.values(retention).map((retention, index) => (
        <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
          {" "}
          {retention}
        </Typography>
      ))}

      <p
        style={{
          fontSize: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "600",
        }}
      >
        4. THIRD-PARTY LINKS
      </p>

      {Object.values(thirdparty).map((thirdparty, index) => (
        <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
          {" "}
          {thirdparty}
        </Typography>
      ))}

      <p
        style={{
          fontSize: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "600",
        }}
      >
        5. SECURITY OF YOUR PERSONAL INFORMATION
      </p>

      {Object.values(security).map((security, index) => (
        <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
          {" "}
          {security}
        </Typography>
      ))}

      <p
        style={{
          fontSize: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          fontWeight: "600",
        }}
      >
        6. GRIEVANCE OFFICER
      </p>

      {Object.values(officer).map((officer, index) => (
        <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
          {" "}
          {officer}
        </Typography>
      ))}

      {Object.values(details).map((details, index) => (
        <Typography variant="body2" gutterBottom sx={{ marginTop: "20px" }}>
          {" "}
          {details}
        </Typography>
      ))}
    </Box>
  );
}
