// import { Grid, Paper } from "@mui/material";
// import { collection, DocumentData, onSnapshot, query, where } from "firebase/firestore";
// import React from "react";
// import { useEffect, useState } from "react";
// import { database } from "../../Firebase/Firebase";
// import { useAuthContext } from "../../Providers/AuthProvider";
// import ApprovedAssignmentDetails from './ApprovedElement.tsx';

// const ApprovedAssignments: React.FunctionComponent = () => {
//     const {user} = useAuthContext();
//     const [mounted, setMounted] = useState(false);
//     const [submittedAss, setSubmittedAss] = useState<DocumentData>([]);
//     useEffect(() => {
//         setMounted(true);
//         return () => {
//             setMounted(false);
//         }
//     }, []);
//     useEffect(() => {
//         let unsubscribe = () => {};
//         if (user !== null && user.uid !== undefined) {
//             unsubscribe = onSnapshot(query(collection(database, 'assignments'),
//             where('status', '==', 'approved'),
//             where('agency', '==', user.uid),
//             ), snapshot => {
//                 let ass : Array<DocumentData> = [];
//                 snapshot.docs.forEach((doc) => {
//                     ass.push({ ...doc.data(), id: doc.id});
//                 });

//                 console.log(ass)
//                 setSubmittedAss(ass);
//             });
//         }
//         return () => {
//             return unsubscribe();
//         }
//     }, [mounted, user]);
//     return (
//         <div>
//             <Paper elevation={0} sx={{
//                     width: "100%",
//                     padding: '0.4em',
//                     margin: '0.3em 0',
//                     fontSize: '14px',
//                     display: 'inline-flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     textAlign: 'center'
//                 }}>
//                     <Grid container>
//                         <Grid item xs={12} sm={12} lg={4} md={4} sx={{
//                             display: 'flex', justifyContent: 'center',
//                             alignItems: 'center'
//                         }}>
//                             <div style={{color: 'gray', fontWeight: 'bold'}}>
//                                 ID
//                             </div>
//                         </Grid>
//                         <Grid item xs={12} sm={12} lg={2} md={2} sx={{
//                             display: 'flex', justifyContent: 'center',
//                             alignItems: 'center'
//                         }}>
//                             <div style={{color: 'gray', fontWeight: 'bold'}}>
//                                 Form Name
//                             </div>
//                         </Grid>

//                         <Grid item xs={12} sm={12} lg={4} md={4} sx={{
//                             display: 'flex', justifyContent: 'center',
//                             alignItems: 'center'
//                         }}>
//                             <div style={{color: 'gray', fontWeight: 'bold'}}>
//                                 Verifier  Name
//                             </div>
//                         </Grid>

//                         <Grid item xs={12} sm={12} lg={2} md={2} sx={{
//                             display: 'flex', justifyContent: 'center',
//                             alignItems: 'center'
//                         }}>
//                             <div style={{color: 'gray', fontWeight: 'bold'}}>
//                                 Action
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </Paper>
//                 <hr style={{width: '93%', margin: '0 auto 0.15em auto', border: '0.6px solid #dedede'}}/>
//             {submittedAss.map((assignment) => {
//             return (<div key={assignment.id}>
//                     <ApprovedAssignmentDetails
//                       id={assignment.id}
//                       assigned_to={assignment.assigned_to}
//                       assigned_name={assignment.assigned_name}
//                       form_name={assignment.formName}
//                     />
//         <hr style={{width: '93%', margin: '0 auto 0.15em auto', border: '0.6px solid #dedede'}}/>
//             </div>)
//         })}
//         </div>
//     );
// }

// export default ApprovedAssignments;

import {
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Divider,
} from "@mui/material";
import {
  collection,
  DocumentData,
  onSnapshot,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import React from "react";
import { useEffect, useState } from "react";
import { database } from "../../Firebase/Firebase";
import { useAuthContext } from "../../Providers/AuthProvider";
import ApprovedAssignmentDetails from "./ApprovedElement.tsx";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ManageAccounts } from "@mui/icons-material";

const ApprovedAssignments: React.FunctionComponent = () => {
  const { user } = useAuthContext();
  const [mounted, setMounted] = useState(false);
  const [submittedAss, setSubmittedAss] = useState<DocumentData[]>([]);
  const [selectedFormName, setSelectedFormName] = useState<string>("All");
  const [selectedAssignedName, setSelectedAssignedName] =
    useState<string>("All");
  const [archivedAss, setArchivedAss] = useState<DocumentData[]>([]); // New state for archived assignments
  const navigate = useNavigate();

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    let unsubscribe = () => {};
    if (user && user.uid && mounted) {
      let assignmentQuery = collection(database, "assignments");

      // Apply filters based on selected form name and assigned name
      let filters = [
        where("status", "==", "approved"),
        where("agency", "==", user.uid),
      ];

      if (selectedFormName && selectedFormName !== "All") {
        filters.push(where("formName", "==", selectedFormName));
      }

      if (selectedAssignedName && selectedAssignedName !== "All") {
        filters.push(where("assigned_name", "==", selectedAssignedName));
      }

      assignmentQuery = query(assignmentQuery, ...filters);

      unsubscribe = onSnapshot(assignmentQuery, (snapshot) => {
        const ass: DocumentData[] = [];
        snapshot.docs.forEach((doc) => {
          ass.push({ ...doc.data(), id: doc.id });
        });
        setSubmittedAss(ass);
      });
    }
    return unsubscribe;
  }, [mounted, user, selectedFormName, selectedAssignedName]);

  const handleFormNameChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setSelectedFormName(event.target.value as string);
  };

  const handleAssignedNameChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setSelectedAssignedName(event.target.value as string);
  };

  // Get unique form names and assigned names from submitted assignments
  const formNames = [
    "All",
    ...new Set(submittedAss.map((ass) => ass.formName)),
  ];
  const assignedNames = [
    "All",
    ...new Set(submittedAss.map((ass) => ass.assigned_name)),
  ];
  const approvedCount = submittedAss.length;

  // const archiveAssignment = (id: string) => {
  //     console.log("Archive clicked for ID:", id);

  //     const assignmentToArchive = submittedAss.find((ass) => ass.id === id);
  //     if (assignmentToArchive) {
  //         setSubmittedAss((prev) => {
  //             const updated = prev.filter((ass) => ass.id !== id);
  //             console.log("Updated submittedAss:", updated);
  //             return updated;
  //         });

  //         setArchivedAss((prev) => {
  //             const updated = [...prev, assignmentToArchive];
  //             console.log("Updated archivedAss:", updated);
  //             return updated;
  //         });
  //     } else {
  //         console.log("Assignment not found in submittedAss");
  //     }
  // };

  const archiveAssignment = async (id: string) => {
    console.log("Archiving assignment:", id);

    const assignmentToArchive = submittedAss.find((ass) => ass.id === id);
    if (assignmentToArchive) {
      try {
        // Update the status in Firebase
        await updateDoc(doc(database, "assignments", id), {
          status: "archived",
        });

        // Remove it from submitted assignments state
        setSubmittedAss((prev) => prev.filter((ass) => ass.id !== id));

        // Add it to archived assignments state
        setArchivedAss((prev) => [...prev, assignmentToArchive]);

        console.log("Assignment archived successfully");
        // showSuccess("Assignment archived successfully!");
      } catch (error) {
        console.error("Error archiving assignment:", error);
        // showError("Failed to archive the assignment.");
      }
    } else {
      console.log("Assignment not found in submitted assignments");
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: 1,
              gap: "5px",
              background: "#17236E",
            }}
            variant="contained"
            color="primary"
            onClick={() => navigate("/dashboard/assignment/archieve")}
          >
            <ManageAccounts />
            Manage Archieve Assignments
          </Button>
        </div>
        <div
          style={{
            gap: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="form-name-select-label" sx={{ marginTop: "-7px" }}>
              Form Name
            </InputLabel>
            <Select
              labelId="form-name-select-label"
              id="form-name-select"
              value={selectedFormName}
              onChange={handleFormNameChange}
              label="Form Name"
              sx={{
                height: 35,
              }}
            >
              {formNames.map((formName, index) => (
                <MenuItem key={index} value={formName}>
                  {formName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel
              id="assigned-name-select-label"
              sx={{ marginTop: "-7px" }}
            >
              Verifier Name
            </InputLabel>
            <Select
              labelId="assigned-name-select-label"
              id="assigned-name-select"
              value={selectedAssignedName}
              onChange={handleAssignedNameChange}
              label="Assigned Name"
              sx={{
                height: 35,
              }}
            >
              {assignedNames.map((assignedName, index) => (
                <MenuItem key={index} value={assignedName}>
                  {assignedName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>
      <Box sx={{ marginY: 5 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "#ffffff" }}>
                <TableCell
                  style={{
                    fontWeight: 700,
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                >
                  Form Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    width: "30%",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                >
                  Verifier Name
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "25%",
                    fontSize: "15px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Divider sx={{ color: "black", width: "100%" }} />

        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {submittedAss.map((assignment) => (
                <div key={assignment.id}>
                  <ApprovedAssignmentDetails
                    id={assignment.id}
                    assigned_to={assignment.assigned_to}
                    assigned_name={assignment.assigned_name}
                    form_name={assignment.formName}
                    archiveAssignment={archiveAssignment} // Pass down the function
                  />
                </div>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ApprovedAssignments;
