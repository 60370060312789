import React, { useEffect, useState, FormEvent } from "react";
import { useLocalState } from "../../Utils/CustomHooks";
import { useAuthContext } from "../../Providers/AuthProvider";
import FormBuilder from "./formBuilder";
import { Print, Edit } from "@mui/icons-material";
import { useProfileContext } from "../../Providers/ProfileProvider";
import { useNavigate, useParams } from "react-router-dom";
import { getUrl } from "../../Utils/StorageMethods";
import { useToastProvider } from "../../Providers/ToastProvider";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
  updateDoc,
} from "firebase/firestore";
import { database } from "../../Firebase/Firebase";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange, grey } from "@mui/material/colors";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";

import { List, ListItem, ListItemText } from "@mui/material";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const PrintScreen = () => {
  const { user } = useAuthContext();
  const [formId, setFormId] = useLocalState("id");

  const [formMapped, setFormMapped] = useState([]);
  const [applicantSelfie, setApplicantSelfie] = useState();
  const [assignment, setAssignment] = useState();
  const [comment, setComment] = useState();
  const [commentKey, setCommentKey] = useState();
  const [form, setForm] = useState();
  const { id } = useParams();
  const [response, setResponse] = useState();
  const { profile } = useProfileContext();
  const agency = profile;
  const navigate = useNavigate();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { showSuccess, showError } = useToastProvider();
  const [open, setOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleImageUpload = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleInputChange = async (event, field) => {
    const { value, files } = event.target;

    if (files) {
      const file = files[0];
      const url = await handleImageUpload(file);
      field.value = url;
    } else if (field.widget === "dropdown") {
      const selectedOption = field.options.find(
        (option) => option.id === Number(value),
      );
      field.value = selectedOption || {}; // Ensure value is an object
    } else {
      field.value = value;
    }

    const updatedFormMapped = formMapped.map((section) => {
      return {
        ...section,
        fields: section.fields.map((f) => {
          if (f === field) {
            return {
              ...f,
              value: field.value,
            };
          }
          return f;
        }),
      };
    });
    setFormMapped(updatedFormMapped);
  };

  const handleClickOpen = () => {
    setOpen(true);
    let pattern = new RegExp("verifier", "i");

    for (let i = 0; i < formMapped.length; i++) {
      const section = formMapped[i];
      if (section.fields) {
        for (let j = 0; j < section.fields.length; j++) {
          const field = section.fields[j];
          // console.log(field);
          console.log(pattern.test(field.label));
          if (pattern.test(field.label)) {
            setComment(response[`${i},${j}`]);
            setCommentKey(`${i},${j}`);
          }
        }
      }
    }
  };

  const handleClickOpenManager = () => {
    setOpen(true);
    let pattern = new RegExp("manager", "i");

    for (let i = 0; i < formMapped.length; i++) {
      const section = formMapped[i];
      if (section.fields) {
        for (let j = 0; j < section.fields.length; j++) {
          const field = section.fields[j];
          // console.log(field);
          console.log(pattern.test(field.label));
          if (pattern.test(field.label)) {
            setComment(response[`${i},${j}`]);
            setCommentKey(`${i},${j}`);
          }
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateComment = () => {
    const docRef = doc(database, "assignments/" + id, "form_data/response");
    updateDoc(docRef, {
      [commentKey]: comment,
    })
      .then(() => {
        console.log("Document successfully updated!");
        getDetails();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const getDetails = async () => {
    try {
      setIsDataLoaded(false);

      const formDataSnapshot = await getDoc(
        doc(database, "assignments", id, "form_data", "data"),
      );
      const formResponseSnapshot = await getDoc(
        doc(database, "assignments", id, "form_data", "response"),
      );
      const assignmentSnapshot = await getDoc(doc(database, "assignments", id));

      const formData = formDataSnapshot.data();
      const formResponse = formResponseSnapshot.data();
      const assignmentData = assignmentSnapshot.data();

      setForm(formData);
      setAssignment(assignmentData);

      let formValues = await mapFormDataToResponse(formData, formResponse);
      formValues = await converValueToURL(formValues);

      setFormMapped(formValues);
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Error fetching details: ", error);
      showError("Failed to fetch details. Please try again later.");
    }
  };

  const mapFormDataToResponse = async (formData, formResponse) => {
    for (let key in formResponse) {
      const array = key.split(",");

      if (array.length === 2) {
        const [sectionIndex, fieldIndex] = array;
        const field = formData.data[sectionIndex]?.fields[fieldIndex];
        if (field) {
          if (field.widget === "dropdown") {
            field.value = formResponse[key];
          } else {
            field.value = formResponse[key];
          }
        }
      } else if (array.length === 4) {
        const [sectionIndex, fieldIndex, rowIndex, columnIndex] = array;
        let row =
          formData.data[sectionIndex]?.fields[fieldIndex]?.rows[rowIndex];
        let column =
          formData.data[sectionIndex]?.fields[fieldIndex]?.columns[columnIndex];

        if (row && column) {
          column.value = formResponse[key];
          row.columns = row.columns || [];
          row.columns.push(column);
        }
      }
    }

    return formData.data;
  };

  const handleSort = (item, secondArg) => {
    console.log(item);
  };

  const converValueToURL = async (data) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].fields.length; j++) {
        if (
          ["image", "geotag_image", "signature", "file"].includes(
            data[i].fields[j].widget,
          )
        ) {
          let pfpUrl = data[i].fields[j].value;
          if (pfpUrl) {
            await getUrl(pfpUrl)
              .then(async (url) => {
                data[i].fields[j].value = url;
              })
              .catch((err) => {
                data[i].fields[j].value = pfpUrl;
              });
          }
        }

        if (data[i].fields[j].widget === "toggle-input") {
          let toggleValue;
          if (data[i].fields[j].value) {
            toggleValue = data[i].fields[j].value ? "yes" : "No";
            data[i].fields[j].value = toggleValue;
          }
        }
      }
    }
    return data;
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    try {
      const docRef = doc(database, "assignments", id, "form_data", "response");

      const dataToUpdate = {};

      formMapped.forEach((section, i) => {
        section.fields.forEach((field, j) => {
          const key = `${i},${j}`;
          if (field.value !== undefined) {
            dataToUpdate[key] = field.value;
          }
        });
      });

      await updateDoc(docRef, dataToUpdate);

      showSuccess("Details updated successfully!");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating details: ", error);
      showError("Failed to update details. Please try again later.");
    }
  };
  const handlePrint = () => {
    let printable = document.getElementById("printablediv").innerHTML;
    const printStyle = `
        @media print {
          @page {
            size: A4;
            margin: 10mm;
          }

          .break-before {
            page-break-before: always;
          }
          .break-after {
            page-break-after: always;
          }
        }
      `;
    const printWindow = window.open("", "_blank", "height=900,width=1200");
    printWindow.document.open();
    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`<style>${printStyle}</style>`);
    printWindow.document.write("</head><body>");
    printWindow.document.write(printable);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };
  return form && assignment && isDataLoaded ? (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          gap: "15px",
        }}
      >
        {!isEditing && (
          <Tooltip title="Print" arrow>
            <Avatar sx={{ bgcolor: grey[200] }} variant="rounded">
              <Print sx={{ color: "black" }} onClick={handlePrint} />
            </Avatar>
          </Tooltip>
        )}
        <Tooltip title="Edit" arrow>
          <Avatar sx={{ bgcolor: grey[200] }} variant="rounded">
            <Edit
              onClick={handleToggleEdit}
              sx={{ color: "black", cursor: "pointer", marginLeft: "10px" }}
            />
          </Avatar>
        </Tooltip>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            handleClose();
          },
        }}
      >
        <DialogTitle>Update comment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={4}
            id="comment"
            name="comment"
            label="comment"
            fullWidth
            variant="outlined"
            defaultValue={comment}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button type="submit" onClick={updateComment}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <div id="printablediv">
        <h1
          style={{
            backgroundColor: "#C00000",
            color: "white",
            marginTop: "20px",
          }}
        >
          {assignment.document_type}
        </h1>
        <div>
          <div>
            <div
              style={{
                fontSize: "25px",
                fontWeight: "800",
                padding: "20px 5px 15px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {form.name}
            </div>
            {formMapped.map((item, index) => (
              <div key={index}>
                <div>
                  <div
                    onClick={() => handleSort(item)}
                    key={index}
                    style={{
                      backgroundColor: "#002060",
                      color: "white",
                      padding: "3px 10px",
                      fontWeight: "800",
                      fontSize: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {item.name}
                  </div>
                  <div>
                    {item.fields.map((item2) => (
                      <>
                        <div
                          style={{
                            border: "1px solid #BABCBD",
                            width: "100%",
                            display: "flex",
                          }}
                          key={item2.label}
                        >
                          <div
                            style={{
                              width: "35%",
                              padding: "3px 10px",
                              display: "inline-block",
                              fontWeight: "800",
                            }}
                          >
                            {item2.label}
                          </div>
                          <div
                            onClick={() => handleSort(item2)}
                            style={{
                              width: "65%",
                              borderLeft: "1px solid black",
                              display: "inline-block",
                            }}
                          >
                            {isEditing || item2.isEditing ? (
                              item2.widget === "image" ||
                              item2.widget === "signature" ||
                              item2.widget === "geotag_image" ||
                              item2.widget === "file" ? (
                                <input
                                  type="file"
                                  onChange={(event) =>
                                    handleInputChange(event, item2)
                                  }
                                />
                              ) : item2.widget === "dropdown" ? (
                                <select
                                  value={item2.value?.id || ""}
                                  onChange={(event) =>
                                    handleInputChange(event, item2)
                                  }
                                >
                                  {item2.options.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.value}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <input
                                  value={item2.value}
                                  onChange={(event) =>
                                    handleInputChange(event, item2)
                                  }
                                />
                              )
                            ) : (
                              <>
                                {item2.widget !== "table" &&
                                  item2.widget !== "dropdown" &&
                                  item2.widget !== "image" &&
                                  item2.widget !== "signature" &&
                                  item2.widget !== "geotag_image" &&
                                  item2.widget !== "file" && (
                                    <div style={{ padding: "3px 10px" }}>
                                      {typeof item2.value === "object"
                                        ? JSON.stringify(item2.value.value)
                                        : item2.value}
                                    </div>
                                  )}
                              </>
                            )}
                            {item2.widget === "dropdown" && (
                              <div style={{ padding: "3px 10px" }}>
                                {item2.value.value}
                              </div>
                            )}
                            {item2.widget === "image" && (
                              <div
                                style={{ padding: "3px 10px", display: "flex" }}
                              >
                                <img
                                  style={{ height: "200px" }}
                                  src={item2.value}
                                  alt="item image"
                                />
                              </div>
                            )}
                            {item2.widget === "signature" && (
                              <div
                                style={{ padding: "3px 10px", display: "flex" }}
                              >
                                <img
                                  style={{ height: "70px", width: "70px" }}
                                  src={item2.value}
                                  alt="signature"
                                />
                              </div>
                            )}
                            {item2.widget === "geotag_image" && (
                              <div
                                style={{ padding: "3px 10px", display: "flex" }}
                              >
                                <img
                                  style={{ height: "300px", width: "350px" }}
                                  src={item2.value}
                                  alt="geotagged image"
                                />
                              </div>
                            )}
                            {item2.widget === "file" && (
                              <div
                                style={{ padding: "3px 10px", display: "flex" }}
                              >
                                <a
                                  href={item2.value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item2.label}
                                </a>
                              </div>
                            )}
                            {item2.widget === "table" &&
                              item2.rows &&
                              item2.rows.length > 0 && (
                                <List>
                                  {item2.rows.map((row, rowIndex) => (
                                    <ListItem key={rowIndex}>
                                      {row.columns.map((column, colIndex) => (
                                        <ListItemText
                                          key={colIndex}
                                          primary={`${column.label}: ${column.value}`}
                                        />
                                      ))}
                                    </ListItem>
                                  ))}
                                </List>
                              )}
                          </div>
                        </div>
                        <br />
                      </>
                    ))}
                  </div>
                </div>
                <br />
                <br />
              </div>
            ))}
          </div>
          {isEditing && (
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          )}
        </div>
        <br />
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h3>Loading...</h3>
    </div>
  );
};

export default PrintScreen;
