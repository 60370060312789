import { Add } from "@mui/icons-material";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormBuilderContext } from "../../Providers/FormBuilderProvider";
import PageItem from "./PageItem/PageItem";
import { useDraftAssignmentContext } from "../../Providers/DraftAssignmentProvider";
import { useNavigate } from "react-router-dom";
import { usePrompt } from "../../Utils/CustomHooks";

const FormBuilderNew = () => {
  const { setForm } = useDraftAssignmentContext();
  const navigate = useNavigate();
  const formNameRef = useRef(null);
  const [cursorPos, setCursorPos] = useState();
  const {
    state,
    dispatch,
    preview,
    setPreview,
    loadingStatus,
    changed,
    setMode,
    setFormId,
    formId,
    mode,
  } = useFormBuilderContext();

  useEffect(() => {}, [cursorPos]);

  usePrompt("Changes will not be saved!\nAre you sure to leave?", changed, [
    "/dashboard/pageBuilder",
  ]);

  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            {/* <TableCell>
              <TextField
                id="formName"
                size="small"
                label="Form Name"
                inputRef={formNameRef}
                value={state.name}
                onChange={(event) => {
                  setCursorPos(event.target.selectionStart);
                  dispatch({
                    type: "changeFormName",
                    payload: { name: event.target.value },
                  });
                }}
              />
            </TableCell> */}
            <TableCell>
              {loadingStatus.status ? (
                <div>
                  <Button>{loadingStatus.message}</Button>
                </div>
              ) : (
                <Tooltip title="Create Form" arrow>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={async () => {
                      dispatch({ type: "saveForm" });
                    }}
                  >
                    Save
                  </Button>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div style={{ margin: "0.3em", display: "inline-flex" }}>
        <Tooltip title="Add page">
          <Paper
            onClick={() => dispatch({ type: "addPage" })}
            variant="outlined"
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              "&:hover": {
                backgroundColor: "whitesmoke",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: "14px",
                padding: "15px",
              }}
            >
              <Add sx={{ color: "lightgrey" }} />
              <p>Create New Page</p>
            </div>
          </Paper>
        </Tooltip>
      </div>

      <div style={{ textAlign: "center" }}>
        <TableContainer component={Paper} style={{ width: "500px" }}>
          <Table>
            <TableBody>
              {state.pages.map((page, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <PageItem page={page} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default FormBuilderNew;
